import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const EducationalPrograms = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='освітні програми'>
			
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1JJ4PrTgqyQ-fng-L6XnFKSVcEi3Q0ylP/view?usp=drive_link" target="_blanck">
					Оператор ДКА
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1iuSoNsL3utIYs7wHVE7zgdf4NQDjQOu-/view?usp=drive_link" target="_blanck">
					Оператор з обробки інформації та ПЗ І категорії
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1pfbNAQR2uHzWnHelFYybFMUmwv3ZcTZ2/view?usp=drive_link" target="_blanck">
					Електрогазозварник 3 розряду
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/16WFkBfQ1V6X5AJ0PrqxYtt8c46gZEFPy/view?usp=drivesdk" target="_blanck">
						ОПП ФМБ Архітектура та будівництво
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1rnwxGKIp72E_nci7NjiGJxkkcPbi31Xt/view?usp=drive_link" target="_blanck">
						ОПП ФМБ Обслуговування та ремонт автомобілів і двигунів
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/16agRP5Uc6LEjgBlfEm68QMCT_842kbii/view?usp=drivesdk " target="_blanck">
						Штукатур 4 розряду освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/16eCbekePFUJPUWXnCH4kLbj4vKcqtPZ_/view?usp=drivesdk " target="_blanck">
						Лицювальник-плиточник 2-3 розрядів освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/16nueb8Ip0P5R9Z9tYl5oeULb2il0abt7/view?usp=drivesdk" target="_blanck">
						Штукатур 2-3 розрядів освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1MDiyYM15j1ikIfu8dJVXcd0b8AS76Ha9/view?usp=share_link" target="_blanck">
						Оператор з обробки інформації та програмного забезпечення освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1s9bmvOsmtQMsFJ9iHz_jpld7fZbwu0sI/view?usp=share_link" target="_blanck">
						Електромеханік з ремонту та обслуговування лічильно-обчислювальних машин 3 розряд освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1NE1fO6zqcVOZOc4KBxNXFgEn8rfYurwp/view?usp=share_link" target="_blanck">
						Електромеханік з ремонту та обслуговування лічильно-обчислювальних машин 4 розряд освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1SWxUO7TDHY6yZ744zS6LeCOHhleedV9I/view?usp=share_link" target="_blanck">
						Електромеханік з ремонту та обслуговування лічильно-обчислювальних машин 5 розряд освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1MtkmtCgveQEWYCjDKfcpRuRuxbK09axI/view?usp=share_link" target="_blanck">
						Електрогазозварник освітня програма
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1VSEdccEFPbTbcnuyPnvClrFC2oXPwgaa/view?usp=share_link" target="_blanck">
						Слюсар з ремонту колісних транспортних засобів освітня програма
					</a>
				</h4>

				<br />
				<h3>Освітні програми (загальна середня освіта)</h3>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1eNczmajenu0Dml-tDM21ogkgBqKBZgqh/view?usp=drive_link" target="_blanck">
					Українська мова (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1yWn1qESn4h_HCGsCIO9TP3XEfiLZpd7h/view?usp=drive_link" target="_blanck">
					Українська література (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1jP9QGQYHiGDLBKtqFQbP8ShFy-m1RzkU/view?usp=drive_link" target="_blanck">
					Історія України. Всесвітня історія
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/12NQvzHMWcfz_FzUqhUGBymdxC1QGdXWD/view?usp=drive_link" target="_blanck">
					Громадянська освіта (інтегрований курс)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ZjTcGimFKdXDSlKLavE_3NUYy5oa2Z1G/view?usp=drive_link" target="_blanck">
					Зарубіжна література (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1Ro39EyaJIOCPMbag7fMXRgXLlDghghMb/view?usp=drive_link" target="_blanck">
					Захист України (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1pCF01AFbQyRI-jjDz2rYulznI8AMu75g/view?usp=drive_link" target="_blanck">
					Іноземні мови (Англійська мова)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1JBrXakt6HbVqS7M2XyeezJbV5XdNslpK/view?usp=drive_link" target="_blanck">
					Інформатика (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1fB_eEHxAzy_gSh4Lirgn0I5cCJLIUnnK/view?usp=drive_link" target="_blanck">
					Математика (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1x0uOQPuHmalGBgR4OZcaxscBpupbMHHG/view?usp=drive_link" target="_blanck">
					Фізична культура (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1uCLzVXJ5mMWvBMmEjTRQklJB20Xvam1b/view?usp=drive_link" target="_blanck">
					Хімія (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1cdd0xwUcSPwQMwNmBrkyilysCOB0Yc96/view?usp=drive_link" target="_blanck">
					Фізика і Астрономія (Авторський колектив під керівництвом Ляшенка О. І.)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/19lWlqhCaijEU_7jFLDNK1CgYwhC3UZ4x/view?usp=drive_link" target="_blanck">
					Біологія і екологія (Рівень стандарту)
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ohhm3dIUAxOL9KADctEiMr8F4r5uDt5E/view?usp=drive_link" target="_blanck">
					Географія  (Рівень стандарту)
					</a>
				</h4>


			</Pattern>

			
				
				
				

			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default EducationalPrograms;
