import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const SocialTeacher = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Педагог соціальний">
			<br />
				<h4>Друк Христина Вікторівна</h4>
				<br />
				<h4>+38 (067) 342 20 42</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1hwOemi2JjINIxqZVvt-SRBVKeOQE0yMO/view?usp=sharing" target="_blanck">
                        Стратегія розвитку закладу освіти
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/share/p/JfCMZckdBSpeQxCT/?mibextid=WC7FNe" target="_blanck">
					Проведення циклу заходів по протидії тероризму
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/share/p/1Q9sKW6d3E8NKxwr/?mibextid=WC7FNe" target="_blanck">
					Благочинна ініціатива для дітей-сиріт ЛВПУ КТБ
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/share/p/XKuYVuQQN8EXWTEa/?mibextid=WC7FNe" target="_blanck">
					Великодні подарунки для дітей з числа незахищених категорій і дітей ВПО, які навчаються у ЛВПУ КТБ
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default SocialTeacher;